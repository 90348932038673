import '../styles/globals.css';
import type { AppProps } from 'next/app';
import '@livekit/components-styles';
import '@livekit/components-styles/prefabs';
import { DefaultSeo } from 'next-seo';
import { useEffect, useState } from 'react';
import type { NextPageContext } from 'next';

interface SeoConfig {
  title: string;
  titleTemplate: string;
  defaultTitle: string;
  description: string;
  twitter: {
    handle: string;
    site: string;
    cardType: string;
  };
  openGraph: {
    url: string;
    images: { url: string; height?: number; width?: number; type: string; }[];
    site_name: string;
  };
  additionalMetaTags: { property: string; content: string }[];
  additionalLinkTags: { rel: string; href: string; sizes?: string; color?: string }[];
}

function getSeoConfig(domain: string): SeoConfig | null {
  if (domain === 'meet.uw-t.com') {
    return {
      title: 'Prototipe Meet',
      titleTemplate: '%s',
      defaultTitle: 'Prototipe Meet',
      description: 'Prototipe meet',
      twitter: {
        handle: '@meet',
        site: '@meet',
        cardType: 'summary_large_image',
      },
      openGraph: {
        url: 'https://meet.uw-t.com',
        images: [
          {
            url: 'https://meet.uw-t.com/images/uw-logo-meet.webp',
            height: 1000,
            type: 'image/png',
          },
        ],
        site_name: 'LiveKit Meet',
      },
      additionalMetaTags: [
        {
          property: 'theme-color',
          content: '#070707',
        },
      ],
      additionalLinkTags: [
        {
          rel: 'icon',
          href: '/favicon.ico',
        },
        {
          rel: 'apple-touch-icon',
          href: '/images/apple-touch.png',
          sizes: '180x180',
        },
        {
          rel: 'mask-icon',
          href: '/images/safari-pinned-tab.svg',
          color: '#070707',
        },
      ],
    };
  } else if (domain === 'lk.synctum.ru' ) {
    return {
      title: 'Aditim Meet',
      titleTemplate: '%s',
      defaultTitle: 'Aditim Meet',
      description: 'Aditim meet',
      twitter: {
        handle: '@meet',
        site: '@meet',
        cardType: 'summary_large_image',
      },
      openGraph: {
        url: 'https://meet.livekit.io',
        images: [
          {
            url: 'https://lk.synctum.ru/images/aditim-logo.png',
            width: 2000,
            height: 1000,
            type: 'image/png',
          },
        ],
        site_name: 'LiveKit Meet',
      },
      additionalMetaTags: [
        {
          property: 'theme-color',
          content: '#070707',
        },
      ],
      additionalLinkTags: [
        {
          rel: 'icon',
          href: '/favicon.ico',
        },
        {
          rel: 'apple-touch-icon',
          href: '/images/apple-touch.png',
          sizes: '180x180',
        },
        {
          rel: 'mask-icon',
          href: '/images/safari-pinned-tab.svg',
          color: '#070707',
        },
      ],
    };
  }else if (domain === 'meet.xlebmarket.ru' ) {
    return {
      title: 'Xlebmarket Meet',
      titleTemplate: '%s',
      defaultTitle: 'Xlebmarket Meet',
      description: 'Xlebmarket meet',
      twitter: {
        handle: '@meet',
        site: '@meet',
        cardType: 'summary_large_image',
      },
      openGraph: {
        url: 'https://meet.xlebmarket.ru',
        images: [
          {
            url: 'https://meet.xlebmarket.ru/images/xm-black.png',
            width: 2000,
            height: 1000,
            type: 'image/png',
          },
        ],
        site_name: 'LiveKit Meet',
      },
      additionalMetaTags: [
        {
          property: 'theme-color',
          content: '#070707',
        },
      ],
      additionalLinkTags: [
        {
          rel: 'icon',
          href: '/favicon.ico',
        },
        {
          rel: 'apple-touch-icon',
          href: '/images/apple-touch.png',
          sizes: '180x180',
        },
        {
          rel: 'mask-icon',
          href: '/images/safari-pinned-tab.svg',
          color: '#070707',
        },
      ],
    };
  }
  return null;
}

MyApp.getInitialProps = async ({ ctx }: { ctx: NextPageContext }) => {
  const domain = ctx.req ? ctx.req.headers.host : window.location.hostname;
  const seoConfig = domain ? getSeoConfig(domain) : null;
  return { seoConfig };
};

function MyApp({ Component, pageProps, seoConfig }: AppProps & { seoConfig: SeoConfig | null }) {
  return (
    <>
      {seoConfig && <DefaultSeo {...seoConfig} />}
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
